import { BrowserRouter as Router, Switch, Route } from "react-router-dom"
import { datadogRum } from '@datadog/browser-rum';
import ClosedTab from "./js/ClosedTab"
import Locked from './js/Locked'
import Blocked from './js/Blocked'
import Paused from './js/Paused'
import BlockedDetail from './js/BlockedDetail'

const prodUrls = [
  // US region prod urls
  "blocked.syd-1.linewize.net",
  "blocked.lax.edu.prd.familyzone.com",
  "cdn.blocked.lax.edu.prd.familyzone.com",
  "blocked.us-1.familyzone.io",
  "blocked.us-1.linewize.net",

  // UK region prod url
  "blocked.uk-1.linewize.net",
  "cdn.blocked.uk-1.linewize.net",

  // AU region prod urls
  "blocked.syd-2.linewize.net",
  "blocked.syd.edu.prd.familyzone.com",
  "cdn.blocked.syd.edu.prd.familyzone.com",
  "blocked.au-1.familyzone.io"
];

if (prodUrls.includes(window.location.hostname)) {
  // See README for local RUM testing instructions
  datadogRum.init({
    clientToken: 'pub53de17c66622ccf3134095604a3d34f5',
    applicationId: 'a18c9db8-01b4-4746-9911-7f8abeedc71a',
    site: 'us5.datadoghq.com',
    service:'block-page-api',
    sampleRate: 5,
    premiumSampleRate: 0,
    trackInteractions: true,
    env: 'production',
    version: process.env.REACT_APP_BUILD_VERSION,
  });
}

function App() {
  return (
    <Router>
      <Switch>
        <Route path="/check" render={() => "OK"}></Route>
        <Route path="/locked"><Locked /></Route>
        <Route path="/closed_tab"><ClosedTab /></Route>
        <Route path="/blocked"><Blocked /></Route>
        <Route path="/paused"><Paused /></Route>
        <Route path="/detail"><BlockedDetail /></Route>
      </Switch>
    </Router>
  );
}

export default App
