import React, {useState} from 'react'
import '../css/Container.css'
import logo from '../images/logo.svg'
import user from '../images/user.svg'
import success from '../images/success.svg'
import xmark from '../images/xmark.svg'

export default function Container({children, showUser, showLogin, showLogout, username, showNotification}) {
    const [closed, setClosed] = useState(false)

    const logoutLink = () => {
        window.location.assign("http://autologout.linewize.net")
    }

    const loginLink = () => {
        window.location.assign("http://autologin.linewize.net")
    }

    const clickCloseIcon = () => {
        setClosed(true)
    }

    return(
        <div className="background">
            <div className="header">
                <img src={logo} className="logo" alt="Linewize Logo"></img>
                
                <div className="logout">
                    {showUser && username && 
                        <React.Fragment>
                            <img src={user} alt="User icon"></img>
                            <div className="username">{username}</div>
                        </React.Fragment>
                    }
                    {showLogin && <div className="button" onClick={loginLink}>Login</div>}
                    {showLogout && <div className="button" onClick={logoutLink}>Logout</div>}
                </div>
            </div>
            {(!closed && showNotification) && <div className="notification">
                <img src={success} alt="Green checkmark" className="notificationIcon" /> Report has been submitted to Linewize <img src={xmark} alt="Close" className="closeIcon" onClick={clickCloseIcon} />
            </div>}
            <div className="container">
                {children}
            </div>
        </div>
    )
}