import { useEffect, useState } from 'react'
import $ from 'jquery'
import '../css/Logo.css'

export default function Logo(props) {
    const [contents, setContents] = useState({})
    const [loading, setLoading] = useState(true)
    const url = `/asset_bucket`
    useEffect(() => {
        setLoading(true)
        $.get(url).then(
            function (response) {
                setContents(response)
                setLoading(false)
            })
    }, [url])

    if (loading)
        return (<></>)

    const schoolLogoFolderName = props.deviceId
    const gcsUrl = "https://storage.googleapis.com/"
    const gcsBucketLocation = gcsUrl + contents.assetBucket + "/"
    const schoolLogoFolderUrl = gcsBucketLocation + schoolLogoFolderName
    const schoolLogoUrl = schoolLogoFolderUrl + "/logo"
    const schoolLogoUrlSvg = schoolLogoFolderUrl + "/logo.svg"
    console.log(schoolLogoUrl)

    return (
        <div>
            <img
                className="schoolLogoHidden contain"
                src={schoolLogoUrl}
                type="image"
                alt="Blocked"
                onLoadStart={
                    ({ currentTarget }) => {
                        currentTarget.hidden = 1;
                    }
                }
                onLoad={
                    ({ currentTarget }) => {
                        currentTarget.hidden = 0;
                        currentTarget.className = "schoolLogo contain"
                    }
                }
                onError={
                    ({ currentTarget }) => {
                        currentTarget.onerror = null;
                        currentTarget.hidden = 1;
                    }
                }
            />

            <img
                className="schoolLogoHidden contain"
                src={schoolLogoUrlSvg}
                type="image/svg+xml"
                alt="Blocked"
                onLoadStart={
                    ({ currentTarget }) => {
                        currentTarget.hidden = 1;
                    }
                }
                onLoad={
                    ({ currentTarget }) => {
                        currentTarget.hidden = 0;
                        currentTarget.className = "schoolLogo contain"
                    }
                }
                onError={
                    ({ currentTarget }) => {
                        currentTarget.onerror = null;
                        currentTarget.hidden = 1;
                    }
                }
            />
        </div>
    );
}
