export default function DetailTable({items}) {
    return (
        <table className="detailTable" cellSpacing="0">
            <tbody>
                {items.map((element, index) => {
                    return <tr key={index}><td>{element[0]}</td><td>{element[1]}</td></tr>
                })}
            </tbody>
        </table>
    )
}