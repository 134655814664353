import { Link, useLocation } from 'react-router-dom'
import { parse } from 'query-string'
import { useEffect } from 'react';

import blocked from '../images/blocked.svg'
import Container from './Container'
import '../css/Contents.css'
import Logo from './Logo'

export default function Blocked() {
    useEffect(() => {
        document.title = "Content Blocked by Linewize"
    }, []);

    const rawArgs = useLocation().search
    const args = parse(rawArgs)
    const detailLink = `/detail${rawArgs}`

    return (
        <Container showUser={true} username={args.user}>
            <Logo deviceId={args.deviceid} />
            <img src={blocked} alt="Blocked"></img>
            <h1 className="title">Content Blocked</h1>
            <div className="subtitle">Access to <span className="subtitleEmphasis">{args.url}</span> has been blocked</div>
            <Link className="plainLink" to={detailLink}><div className="button lightButton">See why it's blocked</div></Link>
        </Container>
    )
}
