import { useLocation } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { parse } from 'query-string'
import $ from 'jquery'
import Container from './Container'
import Bypass from './Bypass'
import DetailTable from './DetailTable'
import Logo from './Logo'

export default function BlockedDetail() {
    useEffect(() => {
        document.title = "Content Blocked by Linewize"
    }, []);

    const [contents, setContents] = useState({})
    const [showNotification, setShowNotification] = useState(false)
    const [downvoted, setDownvoted] = useState(false)
    const [loading, setLoading] = useState(true)
    const url = `/blocked_data${useLocation().search}`
    useEffect(() => {
        setLoading(true)
        $.get(url).then(
            function (response) {
                setContents(response)
                setLoading(false)
            })
    }, [url])

    const args = parse(useLocation().search)

    const showLogin = () => {
        return !args.user
    }

    const showLogout = () => {
        return args.user && contents && contents.allowLogout
    }

    const downvote = () => {
        if (downvoted) {
            return
        }

        $.post("/downvote", contents.downvoteData, function () {
            setShowNotification(true)
            setDownvoted(true)
        });
    }

    const showCustomMessage = () => {
        if (loading || !contents.isActive || !contents.customMessage) {
            return false
        }

        const stripped = contents.customMessage.replace(/\s+/g, '')
        if (stripped === "<p><br></p>" || stripped === "<p></p>") {
            return false
        }

        return true
    }

    const showInternetUsagePolicy = () => {
        return !loading &&
            contents.isActive &&
            contents.internetUsagePolicy
    }

    const showBypassCode = () => {
        return contents.bypassCodeEnabled
    }

    return (
        <Container showUser={true} username={args.user} showLogin={showLogin()} showLogout={showLogout()} showNotification={showNotification}>
            <Logo deviceId={args.deviceid} />
            {loading && <div className="spinner detailSpinner" alt="Loading..."></div>}
            {showCustomMessage() && <div id="message" dangerouslySetInnerHTML={{ __html: contents.customMessage }} />}
            {showInternetUsagePolicy() && <a className="link plainLink" href={contents.internetUsagePolicy}>Internet Usage Policy</a>}
            {!loading && contents.tableData && <DetailTable items={contents.tableData} />}
            <div className="downvoteText">If you believe this site has been incorrectly blocked,<br />you can <span className="link" onClick={downvote}>report it to Linewize.</span></div>
            {showBypassCode() && <Bypass />}
        </Container>
    )
}