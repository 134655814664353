import '../css/Contents.css'
import { useState } from 'react'
import { useLocation } from 'react-router-dom'
import { parse } from 'query-string'
import $ from "jquery"

export default function Bypass() {

    const loadingState = "loading"
    const successState = "success"
    const failureState = "failure"
    const errorState = "error"
    const [bypassState, setBypassState] = useState("")

    const [inputValue, setInputValue] = useState('')
     
    const args = parse(useLocation().search)

    const infoText = () => {
        switch (bypassState) {
            case successState:
                return "Succeeded, redirecting..."
            case failureState:
                return "Bypass code is incorrect"
            case errorState:
                return "Something went wrong, please try again"
            default:
                return "" 
        }
    }

    const infoClass = () => {
        let result = "infoText"
        switch (bypassState) {
            case successState:
                result += " successText"
                break
            case failureState:
            case errorState:
                result += " errorText"
                break
            default:
                break;
        }
        return result;
    }

    const inputClass = () => {
        let result = "bypassInput"
        switch (bypassState) {
            case failureState:
            case errorState:
                result += " bypassInputError"
                break
            default:
                break;
        }
        return result;
    }

    const inputContents = () => {
        switch (bypassState) {
            case loadingState:
                return (
                    <div className="spinner unblock-spinner"></div>
                )
            case successState:
                return (
                    <i class="fa fa-check"></i>
                )
            default:
                return "Unblock"
        }
    }

    const onInputChange = (e) => {
        setInputValue(e.target.value.toUpperCase())
        if (bypassState !== loadingState) {
            setBypassState("")
        }
    }

    const bypass = () => {
        if (bypassState === loadingState || bypassState === successState) {
            return
        }
        setBypassState(loadingState)
        const data = {
            'bypass_code': inputValue,
            'deviceid': args['deviceid'],
            'username': args['user'],
            'ip': args['ip'],
            'mac': args['mac'],
            'cid': args['cid']
        }

        const bypassSuccess = (response) => {
            const result = response['result']
            if (result === 'success') {
                setBypassState(successState)
                let redirectUrl = `${args.url}/${args.path}`
                if (!redirectUrl.startsWith("http://") && !redirectUrl.startsWith("https://")) {
                    redirectUrl = `http://${redirectUrl}`
                }
                const redirectDelay = 2000;
                setTimeout(() => {
                    window.location.assign(redirectUrl)
                }, redirectDelay)
            } else {
                setBypassState(failureState)
            }
        }

        $.post("/bypass_active", data, function(response) {
            bypassSuccess(response)
        }).catch(() => setBypassState(errorState))
    }

    return (
        <div className="bypassContainer flexCentered">
            <div className="bypassTitle">Enter your bypass code to unblock this link</div>
            <div className="bypassContents">
                <div className="flexCentered">
                    <input type="text" 
                        value={inputValue} 
                        onInput={e => onInputChange(e)} 
                        className={inputClass()} 
                        placeholder="Bypass code">    
                    </input>
                    <div className="button unblock" onClick={bypass}>{inputContents()}</div>
                </div>
                <div className={infoClass()}>
                    {infoText()}
                </div>
            </div>
        </div>
    )
}