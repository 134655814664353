import Container from './Container'
import '../css/Contents.css'
import closed from '../images/closed.svg'
import { useEffect } from 'react';

export default function ClosedTab() {
    useEffect(() => {
        document.title = "Tab Closed"
    }, []);
    return (
        <Container>
            <img src={closed} alt="Tab Closed"></img>
            <h1 className="title">Tab Closed</h1>
            <div className="subtitle">Your teacher has closed your last tab.</div>
        </Container>
    )
}