import Container from './Container'
import '../css/Contents.css'
import locked from '../images/locked.svg'
import { useEffect } from 'react';

export default function Locked() {
    useEffect(() => {
        document.title = "Browsing Locked"
    }, []);
    return (
        <Container>
            <img src={locked} alt="Browsing Locked"></img>
            <h1 className="title">Browsing Locked</h1>
            <div className="subtitle">Browsing has been disabled by your teacher.</div>
        </Container>
    )
}