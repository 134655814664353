import { useLocation } from 'react-router-dom'
import { parse } from 'query-string'
import { useEffect } from 'react';

import blocked from '../images/blocked.svg'
import Container from './Container'
import '../css/Contents.css'
import Logo from './Logo'

export default function Paused() {
    useEffect(() => {
        document.title = "Internet Paused by Linewize"
    }, []);

    const rawArgs = useLocation().search
    const args = parse(rawArgs)
    const pausedUntil = args.pausedUntil !== undefined

    return (
        <Container showUser={true} username={args.user}>
            <Logo deviceId={args.deviceid} />
            <img src={blocked} alt="Blocked"></img>
            <h1 className="title">Content Blocked</h1>
            <div className="subtitle">Your parent has paused your internet access
                {pausedUntil && <span className="subtitleEmphasis"><br />until {args.pausedUntil}</span>}
            </div>
        </Container>
    )
}
